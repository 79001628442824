import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { MotivoCierre } from 'src/app/entidades/motivo-cierre';
import { MotivoDesfase } from 'src/app/entidades/motivo-desfase';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaMotivoDesfaseComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  motivo: MotivoCierre;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;

  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaMotivoDesfaseComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (id > 0) {
      this.idSeleccionado = id;
      this.ctx.motivosDesfase.obtener(id).subscribe(a => {
        this.motivo = a;
        Object.assign(this.forma.value, this.motivo);
        this.forma.reset(this.forma.value);
      });
    } else {
      this.idSeleccionado = 0;
      this.motivo = new MotivoCierre();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      clave: ['', Validators.required],
      descripcion: ['', Validators.required]
    });
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.motivo, this.forma.value);

      let observable: Observable<MotivoDesfase>;
      let mensaje = 'Motivo de desfase guardado.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.motivosDesfase.guardar(this.motivo);
      } else {
        guardar = false;
        mensaje = 'Motivo de desfase actualizado';
        observable = this.ctx.motivosDesfase.actualizar(
          this.idSeleccionado.toString(),
          this.motivo
        );
      }

      this.subsGuardar = observable.subscribe(
        () => {
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          if (!guardar) {
            this.ventana.close();
          }
        },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      );
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}
