import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { chain } from 'underscore';
import { RentabilidadDataComponent } from './rentabilidad-data/rentabilidad-data.component';
import { RentabilidadSelectionComponent } from './rentabilidad-selection/rentabilidad-selection.component';
import { RentabilidadDataMoComponent } from './rentabilidad-data-mo/rentabilidad-data-mo.component';
import { Objetivo } from 'src/app/entidades/objetivo';

@Component({
    selector: 'app-grafica-rentabilidad',
    templateUrl: './rentabilidad.component.html',
    styleUrls: ['./rentabilidad.component.scss']
})
export class RentabilidadComponent implements OnInit {
    @Input() year: number = (new Date()).getFullYear();
    estatusVentas: EstatusVentas;
    rastreos: RastreoClienteModel[];
    rastreosF: RastreoClienteModel[];
    promedioAnterior: number;
    objetivo: number;
    objetivos: Objetivo[];

    // fechaFiltro:Date=new Date("2023-01-02");



    // AnnoFiltro:number=2023;

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    callback: (value, index, values) => {
                        return `${value}%`;
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                filter: function name(item, chart) {
                    return !item.text.includes('Servicios');
                }
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${Number(tooltipItem.yLabel).toFixed(2)} %`;
                }
            }
        },
        onClick: (event, active) => this.chartClicked({ event, active })
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: 'Objetivo', type: 'line', datalabels: { display: false }, fill: false, pointRadius: 0 },
        { data: [], label: 'Rentabilidad', type: 'bar', datalabels: { display: false } },
    ];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];

    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.rastreos && this.objetivos) {
            this.actualizarDatos();
        }
    }

    ngOnInit() { }

    // events
    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
        if (active.length > 0) {
            const chart = active[0]['_chart'];
            const colNum = active[0]['_index'];
            const columnName = chart.data.labels[chart.getElementAtEvent(event)[0]['_index']];
            const rastreos = chain(this.rastreosF)
                .filter(c => moment(c.fechaCompromiso).locale('es-mx').format('MMMM') == columnName || `W${moment(c.fechaCompromiso).week()}` == columnName);
            this.ventana.open(RentabilidadSelectionComponent, {
                data: { rastreos: rastreos.value(), objetivo: this.objetivo, colNum, columnName },
                panelClass: 'form-container',
                width: '50vw',
            });
        }
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    cargarDatos(): void {
        this.ctx.documentosVenta
            // .obtenerRentabilidadFiltrado(this.AnnoFiltro)
            .obtenerRentabilidad()
            .pipe(first())
            .subscribe((response) => {
                this.rastreos = response.servicios;
                this.objetivos = response.objetivos;
                this.actualizarDatos();
            });
    }


    actualizarDatos() {
  
        // this.rastreos = response.servicios.filter(c => moment(c.fechaCompromiso).format('YYYY') == moment(this.fechaFiltro).format('YYYY'));
        this.rastreosF = this.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY') == String(this.year));

        const months = chain(this.rastreosF)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const monthValues = chain(months.map(c => c.data)).map(c => {
            const venta = c.reduce((s, f) => s + f.importeVenta, 0);
            const costo = c.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = venta - costo;
            return ((rentabilidad / venta) * 100).toFixed(2);
        }).value();

        const monthKeys = months.map(c => c.monthName);

        this.barChartData.find(c => c.label == 'Rentabilidad').data = monthValues.map(c => Number(c));
        // this.objetivo = response.objetivos.find(c => c.year == Number(moment(this.fechaFiltro).format('YYYY'))).valor;
        this.objetivo = this.objetivos.find(c => c.year == Number(moment(new Date()).format('YYYY'))).valor;
        this.barChartData.find(c => c.label == 'Objetivo').data = Array(monthKeys.length).fill(this.objetivo);
        this.barChartLabels = monthKeys;
        // this.barChartColors[0].backgroundColor = monthKeys.map(v => 'rgba(30, 120, 152, 1)');
        this.barChartData.find(c => c.label == 'Rentabilidad').backgroundColor = '#5B9BD5';
        this.barChartData.find(c => c.label == 'Objetivo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Objetivo').borderColor = '#00B050';
        // this.barChartColors[1].backgroundColor = monthKeys.map(v => 'rgba(226, 245, 39, 0.8)');
    }



    division(a: number, b: number): number {
        if (a == 0) {
            return 0;
        }
        else {
            return b / a;
        }
    }

    abrirForma(): void {
        const forma = this.ventana.open(RentabilidadDataComponent, {
            data: { rastreos: this.rastreosF, promedioAnterior: this.promedioAnterior, objetivo: this.objetivo , yearC: this.year},
            panelClass: 'form-container',
            width: '50vw'
        });
    }

    abrirForma2(): void {
        const forma = this.ventana.open(RentabilidadDataMoComponent, {
            data: { rastreos: this.rastreosF, promedioAnterior: this.promedioAnterior, objetivo: this.objetivo },
            panelClass: 'form-container',
            width: '75vw'
        });
    }
}