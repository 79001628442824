import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { first } from 'rxjs/operators';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { VentasSAPDataComponent } from './Ventas-SAP-data/Ventas-SAP-data.component';
import { VentasPorEnviar } from 'src/app/entidades/VentasPorEnviar';
import { VentasSAPEntregadasComponent } from './ventas-sap-entregadas/ventas-sap-entregadas.component';
import { VentasSAPTotalParcialComponent } from './ventas-sap-total-parcial/ventas-sap-total-parcial.component';
import { VentasSAPFiltroComponent } from './ventas-sapfiltro-component/ventas-sapfiltro-component.component';
import { VentasSinEnviar } from 'src/app/entidades/ventas-sin-enviar';
import { VentasNoEnviarComponent } from 'src/app/modulos/motivo-documentos-enviados/componentes/ventas-no-enviar/ventas-no-enviar.component';
import { VentasSinEnviarSAPComponent } from './ventas-sin-enviar-SAP/ventas-sin-enviar-SAP.component';

export interface Tile {
    color: string;
    cols: number;
    rows: number;
    text: string;
}


@Component({
    selector: 'app-grafica-Ventas-SAP',
    templateUrl: './Ventas-SAP.component.html',
    styleUrls: ['./Ventas-SAP.component.scss']
})
export class VentasSAPComponent implements OnInit {

    @Output() public VentasSAPEvent = new EventEmitter<VentasPorEnviar[]>();

    titulo: string;
    vsemxp: number;
    vseusd: number;
    vtramxp: number;
    vtrausd: number;
    ventmxp: number;
    ventusd: number;
    filter: string = 'none';
    filteredVentasSinEnviar: VentasSinEnviar[];
    sinEnviarMontos = {
        mxp: 0,
        usd: 0
    };
    transitoMontos = {
        mxp: 0,
        usd: 0
    };
    entregadasMontos = {
        mxp: 0,
        usd: 0
    }
    isLoaded: boolean = false;


    ventasSinEnviar: VentasSinEnviar[];

    constructor(private ctx: Contexto, public dialog: MatDialog,public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnInit() { }




    cargarDatos(): void {
        this.ctx.antiguedadSaldos
            .ObtenerVentasComprasSinEnviar()
            .pipe(first())
            .subscribe((response) => {
                console.log(response)
                this.vtramxp = response.ventasTransitoMxp;
                this.vtrausd = response.ventasTransitoUsd;
                this.sinEnviarMontos.mxp = response.ventasTransitoMxp + response.ventasEntregadasMxp;
                this.sinEnviarMontos.usd = response.ventasTransitoUsd + response.ventasEntregadasUsd;
                this.entregadasMontos.mxp = response.ventasEntregadasMxp;
                this.entregadasMontos.usd = response.ventasEntregadasUsd;
                this.transitoMontos.mxp = response.ventasTransitoMxp;
                this.transitoMontos.usd = response.ventasTransitoUsd;
                this.ventmxp = response.ventasEntregadasMxp;
                this.ventusd = response.ventasEntregadasUsd;
                this.vsemxp = response.ventasTransitoMxp + response.ventasEntregadasMxp;
                this.vseusd = response.ventasTransitoUsd + response.ventasEntregadasUsd;

                this.ventasSinEnviar = response.ventasSinEnviarSAP;

                this.isLoaded = true;
            });
    }

    abrirFormaSinEnviar(): void {
        if (this.filter == 'none') {
            this.titulo = "( Sin Enviar a SAP )";
            const forma = this.ventana.open(VentasSAPEntregadasComponent, {
                data: { documentosPendientes: this.ventasSinEnviar, titulo: this.titulo, filter: this.filter },
                panelClass: 'form-container',
                width: '90vw'
            });
        } else {
            this.titulo = `( Sin Enviar a SAP ${this.filter} )`;
            const forma = this.ventana.open(VentasSAPEntregadasComponent, {
                data: { documentosPendientes: this.filteredVentasSinEnviar, titulo: this.titulo, filter: this.filter },
                panelClass: 'form-container',
                width: '90vw'
            });
        }
    }

    abrirFormaTransito(): void {
        if (this.filter == 'none') {
            this.titulo = "( Transito )";
            const forma = this.ventana.open(VentasSAPDataComponent, {
                data: { Ventas: this.ventasSinEnviar.filter(x => x.estatusXmonitor != "Enviada"), titulo: this.titulo},
                panelClass: 'form-container',
                width: '90vw'
            });
        } else {
            this.titulo = `( Transito ${this.filter} )`;
            const forma = this.ventana.open(VentasSAPDataComponent, {
                data: { Ventas: this.filteredVentasSinEnviar.filter(c => c.estatusXmonitor != "Enviada"), titulo: this.titulo},
                panelClass: 'form-container',
                width: '90vw'
            });
        }
    }

    abrirFormaEntregada(): void {

        if (this.filter == 'none') {
            this.titulo = "( Entregada )";
            const forma = this.ventana.open(VentasSAPDataComponent, {
                data: { Ventas: this.ventasSinEnviar.filter(x => x.estatusXmonitor == "Enviada"), titulo: this.titulo},
                panelClass: 'form-container',
                width: '90vw'
            });
        } else {
            this.titulo = `( Entregada ${this.filter} ) `;
            const forma = this.ventana.open(VentasSAPDataComponent, {
                data: { Ventas: this.filteredVentasSinEnviar.filter(c => c.estatusXmonitor == "Enviada"), titulo: this.titulo},
                panelClass: 'form-container',
                width: '90vw'
            });
        }
    }


    abrirFormaTotalParcial(): void {
        const dialogRef = this.ventana.open(VentasSAPTotalParcialComponent, {
            data: { Ventas: this.ventasSinEnviar.filter(x => x.estatusXmonitor == "Enviada") },
            panelClass: 'form-container',
            width: '28vw'
        })

        dialogRef.afterClosed().subscribe(result => {
            console.log('Ventana cerrada')
        });
    }

    updateSinEnviarValues(): void {
        if (this.filter != 'none') {
            this.filteredVentasSinEnviar = this.ventasSinEnviar.filter(x => x.nombreCliente == this.filter);
            this.vtramxp = this.filteredVentasSinEnviar
                        .filter(x => x.monedaVenta == "MXP" && x.estatusXmonitor != "Enviada")
                        .reduce((acc, curr) => acc + curr.importeMonedaDocumento, 0);
            this.vtrausd = this.filteredVentasSinEnviar
                        .filter(x => x.monedaVenta == "USD" && x.estatusXmonitor != "Enviada")
                        .reduce((acc, curr) => acc + curr.importeMonedaDocumento, 0);    
            this.ventmxp = this.filteredVentasSinEnviar
                        .filter(x => x.estatusXmonitor == "Enviada" && x.monedaVenta == "MXP")
                        .reduce((acc, curr) => acc + curr.importeMonedaDocumento, 0);
            this.ventusd = this.filteredVentasSinEnviar
                        .filter(x => x.estatusXmonitor == "Enviada" && x.monedaVenta == "USD")
                        .reduce((acc, curr) => acc + curr.importeMonedaDocumento, 0);
            this.vsemxp = this.vtramxp + this.ventmxp;
            this.vseusd = this.vtrausd + this.ventusd;
        } else {
            this.vsemxp = this.sinEnviarMontos.mxp;
            this.vseusd = this.sinEnviarMontos.usd;
            this.vtramxp = this.transitoMontos.mxp;
            this.vtrausd = this.transitoMontos.usd;
            this.ventmxp = this.entregadasMontos.mxp;
            this.ventusd = this.entregadasMontos.usd;
        }
    }

    abrirFormaFiltroSinEnviar(): void {
        const dialogRef = this.ventana.open(VentasSAPFiltroComponent, {
            data: { documentosPendientes: this.ventasSinEnviar },
            panelClass: 'form-container',
            width: '40vw'
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result != 'none') {
                this.filter = result.cliente;
                console.log(this.filter);
            } else {
                this.filter = result;
                console.log(this.filter);
            }
            this.updateSinEnviarValues();
        });
    }


    openDialog(): void {
        const dialogRef = this.dialog.open(VentasSinEnviarSAPComponent,{
          width: '700px',
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }
}