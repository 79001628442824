import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {  MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import * as XLSX from "xlsx";
import { BuscadorCvComponent } from 'src/app/inicio/buscador-cv/buscador-cv.component';
import { Contexto } from 'src/app/api/contexto.service';
import { VentasSinEnviar } from 'src/app/entidades/ventas-sin-enviar';


@Component({
  selector: 'app-ventas-sap-entregadas',
  templateUrl: './ventas-sap-entregadas.component.html',
  styleUrls: ['./ventas-sap-entregadas.component.scss']
})
export class VentasSAPEntregadasComponent implements OnInit {
  fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort) ordenador: MatSort;


  columnasMostradas = [
    'codigoCliente',
    'nombreCliente',
    'venta',
    'fechaVentaXmonitor',
    'estadoVentaXmonitor',
    'monedaVenta',
    'nombreJefe',
    'importeVentaMxn',
    'importeVentaUsd',
    'codigoProveedor',
    'proveedor',
    'compra',
    'monedaCompra',
    'importeCompraMxn',
    'importeCompraUsd',
    'fechaDocumento',
    'ctFechaEnvioSap',
    'estatusReferi',
    'estatusCtSap',
];


titulo: string;
data = []


  constructor(private ctx: Contexto,
    @Inject(MAT_DIALOG_DATA) public object: { documentosPendientes: VentasSinEnviar[], titulo: string},
    private ventana: MatDialog) {
  }

  ngOnInit(): void {
    this.titulo = this.object.titulo;
    this.fuenteDatos = new MatTableDataSource(this.object.documentosPendientes);
    this.fuenteDatos.sort = this.ordenador;
  }

  getTotal(columna: string): number{
    let total = 0;

    total = this.fuenteDatos.filteredData.reduce((acc, row) => acc + row[columna], 0);

    return total;
  }

  exportExcel(){

    let tabla = document.getElementById('tabla');

    let wb = XLSX.utils.table_to_book(tabla, <XLSX.Table2SheetOpts>{
        sheet: `${this.titulo}`
      });
    XLSX.writeFile(wb, `Ventas ${this.titulo}.xlsx`);

  }

  buscarCV(noCV: number){
    this.ctx.documentosVenta.buscarAnalisisVentasEspecificoPromise(noCV)
    .then(analisis => {            
        if (analisis.length > 0) {
            const dialogRef = this.ventana.open(BuscadorCvComponent, {
              width: '80vw',
              data: { noCV, fromVentas: true}
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log(result);
              if(result){
                this.ventana.closeAll();
              }
            });
            
        }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();

    if (this.fuenteDatos.paginator) {
      this.fuenteDatos.paginator.firstPage();
    }
  }

}
