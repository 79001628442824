import { Injectable } from '@angular/core';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable } from 'rxjs';
import { Documento } from 'src/app/entidades/documento';
import { Proyecto } from 'src/app/entidades/proyecto';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FlujoService {
    private _parametros = {} as ParametrosFlujoVentas;
    public get parametros(): ParametrosFlujoVentas {
        return this._parametros;
    }

    constructor(private ctx: Contexto) {}

    /**
     * @param mantenerVentaSeleccionado - Mantiene abierto el panel de la venta seleccionada previamente en
     * la pantalla de flujo de documentos.
     */
    public cargarParametros(
        proyecto: Proyecto,
        mantenerVentaSeleccionado = false,
        esReporteCerrados,
        idNuevaVentaSeleccionada = 0
    ): Observable<Documento[]> {
        this._parametros.proyecto = proyecto;
        if (!mantenerVentaSeleccionado) {
            this._parametros.idVentaSeleccionada = 0;
        }
        if (idNuevaVentaSeleccionada > 0) {
            this._parametros.idVentaSeleccionada = idNuevaVentaSeleccionada;
        }
        if (esReporteCerrados) {
            this._parametros.esReporteCerrado = true;
        } else {
            this._parametros.esReporteCerrado = false;
        }

        return this.ctx.documentosVenta
            .obtenerFlujo(proyecto.id)
            .pipe(
                tap(
                    (ventas) =>
                    
                        (this._parametros.ventas = ventas.sort((a, b) =>
                            a.id > b.id ? -1 : 1
                        ))
                )
            );
    }
}

export interface ParametrosFlujoVentas {
    proyecto: Proyecto;
    ventas: Documento[];
    idVentaSeleccionada: number;
    esReporteCerrado: boolean;
    distancia?: number;
    idDocumento?: number;
    tipoComponente?: String;
    documentoAccion?: String;

}
