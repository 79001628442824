import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { MotivoCancelacion } from '../entidades/motivo-cancelacion';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioMotivoCancelacion extends RepositorioCatalogo<
  MotivoCancelacion
> {
  constructor(http: HttpClient) {
    super(http, 'MotivosCancelacion');
  }
}
