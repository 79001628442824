import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { even } from '@rxweb/reactive-form-validators';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Contexto } from 'src/app/api/contexto.service';
import { Objetivo } from 'src/app/entidades/objetivo';
import { Socio } from 'src/app/entidades/socio';
import { TablaDashboard } from 'src/app/enumeraciones/enumeraciones';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
    selector: 'app-objetivos',
    templateUrl: './objetivos.component.html',
    styleUrls: ['./objetivos.component.scss'],
})
export class ObjetivosComponent implements OnInit {
    forma: FormGroup;
    tablas = Object.values(TablaDashboard);
    socios: Socio[] = [];
    sociosF: Socio[] = [];
    sociosSeleccionados: Socio[] = [];
    socioDescripcion = new FormControl('');
    years = this.generateYearsBetween();
    sociosFiltrados: Observable<Socio[]>;
    popo: Socio[] = [];

    get f() { return this.forma.controls; }

    constructor(private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta) { }

    ngOnInit() {
        this.generarForma();
        this.ctx.socios.obtenerTodos().subscribe(response => {
            this.socios = response;
            this.sociosF = response.filter(e => e.esCliente == true);
            this.sociosFiltrados = this.socioDescripcion.valueChanges.pipe(
                startWith<string | Socio>(''),
                map((socio) =>
                    typeof socio === 'string'
                        ? socio
                        : socio == null
                            ? ''
                            : socio.nombreComercial
                ),
                map((socio) => this._filter(socio))
            );
        });
    }

    displayFn(socio: Socio): string {
        return socio && socio.nombreComercial ? socio.nombreComercial : '';
    }

    private _filter(value: string): Socio[] {
        const filterValue = value.toLowerCase();
        return this.socios.filter(option => option.nombreComercial.toLowerCase().includes(filterValue));
    }

    generarForma(): void {
        this.forma = this.formBuilder.group(
            {
                id: [0],
                year: [null, Validators.required],
                tabla: [null, Validators.required],
                sociosExcluidos: [null],
                valor: [null]
            }
        );
    }

    generateYearsBetween(startYear = 2019, endYear = new Date().getFullYear()) {
        const endDate = endYear || new Date().getFullYear();
        let years = [];

        for (var i = startYear; i <= endDate; i++) {
            years.push(startYear);
            startYear++;
        }
        return years;
    }

    cargarDatos() {
        if (this.forma.valid) {
            const model = this.forma.value as Objetivo;
            this.ctx
                .objetivos
                .obtenerPorClienteYear(model.year, model.tabla)
                .subscribe(
                    objetivo => {
                        Object.assign(this.forma.value, objetivo);
                        this.forma.reset(this.forma.value);
                        this.obtenerClientesExcluidos(model.year, model.tabla);
                    });
        }

    }

    guardar() {
        console.log(this.forma)
        if (this.forma.valid) {
            const model = this.forma.value as Objetivo;
            this.ctx.objetivos.guardar(model).subscribe(response => {
                Object.assign(this.forma.value, response);
                this.forma.reset(this.forma.value);
                this.alerta.mostrarExito('Objetivo guardado con exito');
            });
        }
    }

    obtenerClientesExcluidos(year, tabla){
        this.ctx.objetivos.obtenerClientesExcluidos(year, tabla).subscribe(
            clientesSeleccionados => {
                var clientesSeleccionadosId = clientesSeleccionados.map(cliente => cliente.id);
                console.log(clientesSeleccionadosId);

                this.forma.get('sociosExcluidos').setValue(clientesSeleccionadosId);
            }
        )
    }
}