export enum EstadoDocumento {
    Pendiente = 1,
    Listo = 2,
    Cerrado = 3,
    NoFacturar = 4,
}

export enum CategoriasDocumento {
    SolicitudVenta = 1,
    SolicitudCostos,
    CotizacionCostos,
    CotizacionVenta,
}

export enum CategoriaEmpresa {
    Logistica = 1,
    Transporte = 2,
    Ambos = 3,
}

export enum perfilProyecto {
    Logistica = 1,
    MotionUSA = 2,
    Transporte = 3,
}

export enum CheckBoxType { 
    NONE = 0,
    HABRA_CONTINUIDAD = 1, 
    ES_CONTINUIDAD = 2 
};

export enum TablaDashboard {
    PerfectOrder = 'Perfect Order',
    CierreDespachoOperativo = 'Cierre Despacho Operativo',
    Rentabilidad = 'Rentabilidad',
    RetrasoCobranza = 'Retraso Cobranza',
    PeriodoPromedioPago = 'Periodo Promedio Pagos',
    PruebaAcida = 'Prueba Acida',
    RecuperacionCartera = 'Recuperacion Cartera',
    CotizacionesNegocio = 'Cotizaciones Convertidas Negocio',
    TiempoRespuestaCliente = 'Tiempo Respuesta Cliente'
}