import { first } from 'rxjs/operators';
import { Documento } from 'src/app/entidades/documento';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Contexto } from 'src/app/api/contexto.service';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from '../../loading/services/loading.service';
import { ComponentesService } from '../../operaciones/servicios/componentes.service';


@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Documento> = new MatTableDataSource([]);
  columnasMostradas = [
      'folio',
      'proyectoFolio',
      'subtotal',
      'fechaCierre',
      'motivoCierre',
      'nuevaCotizacion',
      'opciones',
  ];
  cargando = true;

  subsCompra: Subscription;

  public get diametro(): number {
      if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
          return 50;
      }
      return 100;
  }

  subsProyecto: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  subsSolicitud: Subscription;

  constructor(
      public ventana: MatDialog,
      private ctx: Contexto,
      private alerta: ServicioAlerta,
      private autenticacion: Autenticacion,
      private parametrosService: ComponentesService,
      private router: Router,
      private spinner: LoadingService,
      private ventanaModal: MatDialog
  ) {}

  idCliente: number;
  subsCompras: Subscription;
  usuarioId: number;

  ngOnInit() {
      this.idCliente = this.autenticacion.perfil.id;
      this.cargarSvCerradas();
  }

  cargarSvCerradas(): void {
      this.cargando = true;
      this.ctx.documentosVenta
          .obtenerSvCerradas(this.idCliente)
          .subscribe((svs) => {
   
              this.fuenteDatos = new MatTableDataSource(svs);
              this.fuenteDatos.paginator = this.paginador;
              this.fuenteDatos.sort = this.ordenador;
              this.cargando = false;
          });
  }

  filtrar(filterValue: string) {
      this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  recargarSvs(): void {
      this.cargarSvCerradas();
  }

  abrirSv(svId: number){
    this.cargando = true;
    this.ctx.documentosVenta.abrirDocumento(svId)
    .pipe(first())
    .subscribe(() => {
      this.recargarSvs();
      this.cargando = false;
      this.alerta.mostrarExito('Documento abierto.');
    },
    error => {
      this.alerta.mostrarExito('Ocurrió un error');
    })

  }

  ngOnDestroy(): void {}
}
