import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camion } from '../entidades/camion';
import { AsignacionCamion } from '../entidades/asignacion-camion';
import { CfdiEmisor } from '../entidades/cfdi-emisor';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Objetivo } from '../entidades/objetivo';
import { Socio } from '../entidades/socio';

export class RepositorioObjetivos extends RepositorioCatalogo<Objetivo> {
  constructor(http: HttpClient) {
    super(http, 'Objetivos');
  }

  obtenerPorClienteYear(year: number, tabla: string): Observable<Objetivo> {
    const ruta = `${this.ruta}/Obtener/${year}/${tabla}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Objetivo) => {
        return data;
      })
    );
  }
  obtenerClientesExcluidos(year: number, tabla: string): Observable<Socio[]> {
    console.log(year, tabla);
    const ruta = `${this.ruta}/ObtenerClientesExcluidos/${year}/${tabla}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Socio[]) => {
        return data;
      })
    );
  }  
}
