import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { EstatusParaCV } from 'src/app/entidades/estatusParaCV';
import { Observable, Subscription } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoEstatusCliente } from 'src/app/entidades/tipo-estatus-cliente';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { VentanaConfirmacionComponent } from 'src/app/modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-estatus-asignados',
    templateUrl: './estatus-asignados.component.html',
    styleUrls: ['./estatus-asignados.component.scss']
})
export class EstatusAsignadosComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['descripcion', 'fecha'];
    columnasMostradasCE = ['descripcion', 'fecha', 'acciones'];

    subsGuardar: Subscription;
    aplicaEntregaTotal = false;

    constructor(
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private ventanaModal: MatDialog,
        @Inject(MAT_DIALOG_DATA) public object: { estatus: EstatusParaCV[], entregaTotal: boolean, idVenta: number },
    ) {
    }

    ngOnInit(): void {
        this.aplicaEntregaTotal = this.object.entregaTotal;

        this.fuenteDatos = new MatTableDataSource(this.object.estatus);
        this.fuenteDatos.sort = this.ordenador;
    }

    EntregaTotal(): void {

        this.ventanaModal
            .open(VentanaConfirmacionComponent, {
                data: {
                    titulo: '¿Asignar estatus completado a documento?',
                    botonVerdad: 'Confirmar',
                    botonFalso: 'Cerrar',
                },
            })
            .afterClosed()
            .toPromise()
            .then(async (resultado: boolean) => {
                if (resultado) {
                    this.ctx.tiposEstatusClientes
                        .AsignoEstatusEntregaTotal(this.object.idVenta, 9)
                        .pipe(first())
                        .subscribe(
                            () => {
                                this.alerta.mostrarExito('Estatus actualizado con exito')
                            },
                            (error: any) => {
                                this.alerta.mostrarError('Error al actualizar eestatus')
                            }
                        );
                }
            });

    }

}