import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaProyectosComponent } from '../forma/forma.component';
import { Subscription, Observable } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoProyecto } from 'src/app/entidades/tipo-proyecto';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Router } from '@angular/router';
import { FlujoService } from 'src/app/modulos/flujo-ventas/servicios/flujo.service';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ComponentesService } from 'src/app/modulos/operaciones/servicios/componentes.service';
import { AlertaFaltaFacturaComponent } from '../alerta-falta-factura/alerta-falta-factura.component';
import { CatalogoListaComprasVentasComponent } from '../lista-compras-ventas/catalogo.component';
import { ProyectoCerradoDetalleComponent } from './proyecto-cerrado-detalle/proyecto-cerrado-detalle.component';
import { ProyectoDetalleComponent } from './proyecto-detalle/proyecto-detalle.component';


@Component({
    selector: 'catalogo-proyecto',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
    animations: [animacionTabla],
})
@AutoUnsubscribe()
export class CatalogoProyectosComponent implements OnInit {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatos: MatTableDataSource<Proyecto> = new MatTableDataSource([]);
    columnasMostradas = [
        'folio',
        'descripcion',
        'fechaDocumento',
        'nombreUsuario',
        'esInternacional',
        'perfil',
        // 'estatusProyecto',
        // 'opciones',
    ];
    cargando = true;

    subsCompra: Subscription;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 50;
        }
        return 100;
    }

    subsProyecto: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    subsSolicitud: Subscription;
    tiposProyecto: Observable<TipoProyecto[]>;

    rowExapandido: Proyecto | null = null;

    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        private parametrosService: ComponentesService,
        private servicioFlujo: FlujoService,
        private router: Router,
        private ventanaModal: MatDialog,
        private spinner: LoadingService
    ) { }

    idCliente: number;
    subsCompras: Subscription;
    usuarioId: number;

    ngOnInit() {
        this.idCliente = this.autenticacion.perfil.id;
        this.cargarProyectos();
    }



    cargarProyectos(): void {
        this.cargando = true;
        this.ctx.proyectos
            .obtenerTodosCliente(this.idCliente)
            .subscribe((Proyectos) => {
                this.fuenteDatos = new MatTableDataSource(
                    Proyectos.sort((p1, p2) =>
                        this.compare(p1.folio, p2.folio, false)
                    )
                );
                console.log(Proyectos)
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.cargando = false;
            });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    abrirForma(id?: number): void {
        const dato = id ? id : null;
        const forma = this.ventana.open(FormaProyectosComponent, {
            data: dato,
            panelClass: 'form-container',
        });

        this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
            this.cargarProyectos();
        });
    }

    // actualizarCiente(proyecto: Proyecto): void {

    //     this.spinner.show('Verificando Proyecto...');

    //     this.ctx.proyectos.preActualizarClienteProyecto(String(proyecto.id))
    //         .then(success => {
    //             this.spinner.hide();
    //             console.log('preActualizarClienteProyecto-->', success);
    //             if (success.actualizable) {
    //                 let forma = this.ventanaModal.open(CatalogoActualizaClienteProyectoComponent, {
    //                     data: {
    //                         proyecto,
    //                         clienteId: proyecto.clienteId,
    //                         folio: proyecto.folio
    //                     },
    //                     panelClass: 'form-container',
    //                     disableClose: false,
    //                 })
    //                     .afterClosed()
    //                     .toPromise()
    //                     .then(() => {
    //                         // recargar proyectos
    //                         this.cargarProyectos();
    //                     });
    //             } else {
    //                 this.alerta.mostrarAdvertencia(String(success.mensaje));
    //             }
    //         })
    //         .catch(error => {

    //         });
    // }

    // mostrarFlujo(proyecto: Proyecto): void {
    //     this.spinner.show('Cargando el flujo de documentos...');
    //     this.servicioFlujo
    //         .cargarParametros(proyecto, true, false)
    //         .toPromise()
    //         .then(() => {
    //             this.router
    //                 .navigate(['Empresa/FlujoVentas'])
    //                 .then(() => this.spinner.hide());
    //         });
    // }

    // async abrirSolicitudCotizacionCliente(
    //     proyecto: Proyecto,
    //     idVenta: number = 0
    // ): Promise<void> {
    //     const tipoComponente: TipoComponente =
    //         proyecto.perfilProyectoId == PerfilesProyecto.Logistica
    //             ? 'SV'
    //             : 'CV';
    //     this.ventanaModal
    //         .open(VentanaConfirmacionNuevaComponent, {
    //             data: {
    //                 titulo: '¿Es nueva cotización?',
    //                 botonsi: 'Si',
    //                 botono: 'No',
    //             },
    //         })
    //         .afterClosed()
    //         .toPromise()
    //         .then((nuevaCotizacion: boolean) => {
    //             let resultado = nuevaCotizacion;
    //             this.spinner.show('Cargando parametros...');
    //             this.parametrosService
    //                 .cargarParametros({
    //                     tipoComponente,
    //                     proyecto,
    //                     resultado,
    //                     urlRegreso: 'Empresa/Proyectos',
                        
    //                 })
    //                 .subscribe((e) => {
    //                     this.router
    //                         .navigate([`Empresa/CotizacionVenta`])
    //                         .then(() => this.spinner.hide());
    //                 });
    //         });



    // }

    // async abrirRelacionDocumentosAsignados(proyecto: Proyecto) {
    //     this.ventana
    //         .open(RelacionAsignacionDocumentoComponent, {
    //             data: { id: proyecto.id, folio: proyecto.folio },
    //             panelClass: 'form-container',
    //         })
    //         .afterClosed()
    // }

    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        } else {
            this.desactivar(id);
        }
    }

    activar(id: number): void {
        this.subsActivar = this.ctx.proyectos.activar(id).subscribe(
            () => { },
            () => {
                this.alerta.mostrarError('Error al activar');
            }
        );
    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx.proyectos.desactivar(id).subscribe(
            () => { },
            () => {
                this.alerta.mostrarError('Error al desactivar');
            }
        );
    }

    // eliminar(id: number): void {
    //     this.subsActivar = this.ctx.proyectos.eliminar(id.toString()).subscribe(
    //         () => {
    //             this.alerta.mostrarExito('Registro eliminado');
    //             this.cargarProyectos();
    //         },
    //         () => {
    //             this.alerta.mostrarError('Error al eliminar');
    //         }
    //     );
    // }

    recargarProyectos(): void {
        this.cargarProyectos();
    }

    // seleccionarProyecto(proyectoOrigenId: number): void {
    //   this.ventana
    //     .open(DuplicadoProyectoComponent, {
    //       panelClass: 'form-container'
    //     })
    //     .afterClosed()
    //     .toPromise()
    //     .then((proyectoDestinoId: number) => {
    //       if (proyectoDestinoId) {
    //         this.duplicar(proyectoOrigenId, proyectoDestinoId);
    //       }
    //     });
    // }

    // duplicar(proyectoOrigenId: number): void {
    //     this.spinner.show('Duplicando proyecto');
    //     this.ctx.proyectos
    //         .duplicar(proyectoOrigenId)
    //         .toPromise()
    //         .then(() => {
    //             this.alerta.mostrarExito('Proyecto duplicado');
    //             this.recargarProyectos();
    //             this.spinner.hide();
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             this.alerta.mostrarError('Error al duplicar');
    //         });
    // }

    // cancelar(proyectoId: number): void {
    //     this.ventana
    //         .open(FormaMensajeGlobalComponent, {
    //             data: {
    //                 titulo: '¿Está seguro de cancelar el proyecto?',
    //                 mensaje:
    //                     'El proyecto será cancelado y no se volverá a utilizar',
    //                 botonVerdad: 'Confirmar',
    //                 botonFalso: 'Cerrar',
    //             },
    //         })
    //         .afterClosed()
    //         .toPromise()
    //         .then((resultado: boolean) => {
    //             if (resultado) {
    //                 this.cancelarProyecto(proyectoId);
    //             }
    //         });
    // }

    async cancelarProyecto(proyectoId: number): Promise<void> {
        // this.spinner.show('Procesando respuesta');
        // this.ctx.proyectos
        //     .cancelar(proyectoId)
        //     .toPromise()
        //     .then(() => {
        //         this.alerta.mostrarExito('Proyecto cancelado');
        //     })
        //     .catch((e) => {
        //         if (e.error === 'NoCancelarProyecto') {
        //             this.alerta.mostrarError(
        //                 'Hay documentos que no han sido cancelados'
        //             );
        //         } else {
        //             console.log(e);
        //             this.alerta.mostrarError('Ocurrio un error');
        //         }
        //     })
        //     .finally(() => {
        //         this.spinner.hide();
        //         this.cargarProyectos();
        //     });

        this.spinner.show('Procesando respuesta');
        let respuesta = await this.ctx.proyectos.cancelacionProyecto(proyectoId).toPromise();

        if (respuesta.ok && (respuesta.objeto != undefined || respuesta.objeto != null)) {
            this.ventana
                .open(CatalogoListaComprasVentasComponent, {
                    data: respuesta.objeto,
                    height: '80%',
                    width: '90%',
                    disableClose: false,
                })
                .afterClosed()
                .toPromise()
                .then((resultado: boolean) => { });
                this.spinner.hide();
        } else {

            this.alerta.mostrarExito('Proyecto cancelado');
            this.spinner.hide();
            this.cargarProyectos();
        }
    }

    verificarFacturaEnDocumentos(documentos: string[]) {
        this.ventana.open(AlertaFaltaFacturaComponent, { data: documentos });
    }

    // async cerrar(proyectoId: number): Promise<void> {
    //     const cvsSinFactura = await this.ctx.proyectos
    //         .obtenerDocumentosSinFactura(proyectoId)
    //         .toPromise();
    //     if (cvsSinFactura.length) {
    //         this.verificarFacturaEnDocumentos(cvsSinFactura);
    //         return;
    //     }

    //     this.ventana
    //         .open(FormaMensajeGlobalComponent, {
    //             data: {
    //                 titulo: '¿Está seguro de cerrar el proyecto?',
    //                 mensaje:
    //                     'El estatus cerrara el proyecto y no podrá trabajar más con él',
    //                 botonVerdad: 'Confirmar',
    //                 botonFalso: 'Cerrar',
    //             },
    //         })
    //         .afterClosed()
    //         .toPromise()
    //         .then((resultado: boolean) => {
    //             if (resultado) {
    //                 console.log('Cerro');
    //                 this.cerrarProyecto(proyectoId);
    //             }
    //         });
    // }

    // cerrarProyecto(proyectoId: number): void {
    //     this.spinner.show('Procesando respuesta');
    //     this.ctx.proyectos
    //         .cerrar(proyectoId)
    //         .toPromise()
    //         .then(() => {
    //             this.alerta.mostrarExito('Proyecto cerrado');
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //             this.alerta.mostrarError('Ocurrio un error');
    //         })
    //         .finally(() => {
    //             this.spinner.hide();
    //             this.cargarProyectos();
    //         });
    // }

    // async verCotizacionesPDF(proyectoId: number): Promise<void> {
    //     this.ventana.open(DescargarPDFCotizacionGlobalComponent, {
    //         data: { proyectoId },
    //         panelClass: 'form-container',
    //     });
    // }

    // PDFCotizacionGlobal(proyectoId: number): void {
    //     this.router.navigate(['Empresa/PDFCotizacionGlobal', proyectoId]);
    // }

    abrirProyectoDetalle(proyectoId: number): void {
        this.ventana
        .open(ProyectoDetalleComponent, {
            data: { proyectoId },
            panelClass: 'form-container',
            width: '70vw'
        })
        .afterClosed().subscribe(() => {
            this.cargarProyectos();
        })
    }
}
