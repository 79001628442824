import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MotivoCierre } from 'src/app/entidades/motivo-cierre';
import { FormaMotivoDesfaseComponent } from 'src/app/paginas/motivos-desfase/forma/forma.component';
import { MotivoDesfase } from 'src/app/entidades/motivo-desfase';

@Component({
  selector: 'app-confirmacion-desfase',
  templateUrl: './confirmacion-desfase.component.html',
  styleUrls: ['./confirmacion-desfase.component.scss']
})
export class ConfirmacionDesfaseComponent implements OnInit {
  controlMotivo = new FormControl();
  motivos$: Observable<MotivoDesfase[]>;

  constructor(
    private ctx: Contexto,
    private modal: MatDialog,
    private ventana: MatDialogRef<ConfirmacionDesfaseComponent>
  ) {}

  ngOnInit() {
    debugger
    this.motivos$ = this.ctx.motivosDesfase.obtenerTodosActivos();
  }

  confirmar() {
    debugger
    if (this.controlMotivo.valid) {
      this.ventana.close(this.controlMotivo.value);
    }
  }

  abrirFormaMotivoCierre(e: any): void {
    e.stopPropagation();
    this.modal
      .open(FormaMotivoDesfaseComponent, {
        data: 0,
        panelClass: 'form-container'
      })
      .afterClosed()
      .pipe(first())
      .subscribe(
        () => (this.motivos$ = this.ctx.motivosDesfase.obtenerTodos())
      );
  }
}
