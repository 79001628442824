import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Compra } from '../entidades/compra';
import { DocumentoDatosEquipo } from '../entidades/documento-datos-equipo';
import { DocumentoDuplicar } from '../entidades/documento-duplicar';
import { AnalisisVentas } from '../entidades/analisis-ventas';
import { VentasEmpleado } from '../entidades/ventas-empleado';
import { VentasCanceladas } from '../entidades/ventas-canceladas';
import { EstatusVentas } from '../entidades/estatus-ventas';
import { Documento } from '../entidades/documento';
import { Socio } from '../entidades/socio';
import { AsignacionProyectosCV } from '../entidades/asignacion-proyectos-cv';
import { AsignacionSVCV } from '../entidades/asignacion-sv-cv';
import { Archivo } from '../utilerias/control-archivos/control-archivo.component';
import { Motivo } from '../entidades/motivo-no-envio';
import { SolicitudEmbarque } from '../entidades/solicitud-embarque';
import { Respuesta } from '../entidades/respuesta';
import { TiposDocumentos } from '../entidades/tipos-documentos';
import { MainGraphicModel } from '../entidades/rastreo-cliente';
import { PendienteSAP } from '../entidades/PendienteSAP';
import { FechaCV } from '../entidades/fecha-cv';
import { UbicacionCV } from '../entidades/ubicacionCV';
import { DescripcionSV } from '../entidades/descripcionSV';
import { MainGraphicModel2 } from '../entidades/facturacionPendiente';
import { MainGraphicPartidas } from '../entidades/partidas';


export class RepositorioDocumentoVenta extends RepositorioCatalogo<Documento> {
    constructor(http: HttpClient) {
        super(http, 'DocumentosVenta');
    }

    obtenerSinProcesar(proyectoId: number): Observable<Documento[]> {
        const ruta = `${this.ruta}/SinProcesar/${proyectoId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerTodosSinProcesar(): Observable<Documento[]> {
        const ruta = `${this.ruta}/SinProcesar`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    guardarDocumentoSV(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/GuardarDocumentoSV`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    actualizarDocumentoSV(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/ActualizarDocumentoSV`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    AsignarFechaCV(doc: FechaCV): Observable<FechaCV> {
        const ruta = `${this.ruta}/AsignarFechaCV`;
        return this.clienteHttp.post<FechaCV>(ruta, doc);
    }

    guardarUbicacionCV(doc: UbicacionCV): Observable<UbicacionCV> {
        const ruta = `${this.ruta}/guardarUbicacionCV`;
        return this.clienteHttp.post<UbicacionCV>(ruta, doc);
    }

    guardarUbicacionCVDesfase(doc: UbicacionCV, motivo: number): Observable<UbicacionCV> {
        const ruta = `${this.ruta}/guardarUbicacionCVDesfase/${motivo}`;
        return this.clienteHttp.post<UbicacionCV>(ruta, doc);
    }

    guardarDocumentoCV(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/GuardarDocumentoCV`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    guardarDatosEquipo(
        documento: DocumentoDatosEquipo
    ): Observable<DocumentoDatosEquipo> {
        const ruta = `${this.ruta}/GuardarDatosEquipo`;
        return this.clienteHttp.put<DocumentoDatosEquipo>(ruta, documento);
    }

    obtenerDocumentoDatosEquipo(
        documentoId: number
    ): Observable<DocumentoDatosEquipo> {
        const ruta = `${this.ruta}/ObtenerDocumentoDatosEquipo/${documentoId}`;
        return this.clienteHttp.get<DocumentoDatosEquipo>(ruta);
    }

    obtenerDatosEquipoPendientes(
        proveedorId: number
    ): Observable<DocumentoDatosEquipo[]> {
        const ruta = `${this.ruta}/DatosEquipoPendientes/${proveedorId}`;
        return this.clienteHttp.get<DocumentoDatosEquipo[]>(ruta);
    }

    obtenerVentaDocumento(idDocumento: number): Observable<Documento> {
        const ruta = `${this.ruta}/ObtenerVentaDocumento/${idDocumento}`;
        return this.clienteHttp.get<Documento>(ruta);
    }

    guardarVenta(solicitudes: FormData): Observable<Documento[]> {
        const ruta = `${this.ruta}/GuardarVenta`;
        return this.clienteHttp.post(ruta, solicitudes).pipe(
            map((ventas: Documento[]) => {
                return ventas;
            })
        );
    }

    actualizarVenta(solicitudes: FormData): Observable<Documento[]> {
        const ruta = `${this.ruta}/ActualizarVenta`;
        return this.clienteHttp.post(ruta, solicitudes).pipe(
            map((ventas: Documento[]) => {
                return ventas;
            })
        );
    }

    descargarArchivoCliente(idArhivo: number): Observable<Blob> {
        const ruta = `${this.ruta}/ArchivosCliente/Descargar/${idArhivo}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        }).pipe(map((pdf: Blob) => pdf));
    }
    crearCompra(idVenta: number): Observable<Compra> {
        const ruta = `${this.ruta}/CrearCompra/${idVenta}`;
        return this.clienteHttp.get(ruta).pipe(
            map((compra: Compra) => {
                return compra;
            })
        );
    }

    enviarDocumentoCliente(
        idDocumento: number,
        documento: string
    ): Observable<boolean> {
        const ruta = `${this.ruta}/EnviarDocumentoCliente/${idDocumento}`;
        const doc = { documentoBase64: documento };
        return this.clienteHttp.put<boolean>(ruta, doc);
    }

    autorizarCotizacionVenta(idDocumento: number): Observable<boolean> {
        const ruta = `${this.ruta}/AutorizarCotizacionVenta/${idDocumento}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    enviarDocumentoASeguimiento(idDocumento: number): Observable<boolean> {
        const ruta = `${this.ruta}/EnviarDocumentoASeguimiento/${idDocumento}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    aplicaDatosEquipo(idDocumento: number): Observable<boolean> {
        const ruta = `${this.ruta}/AplicaDatosEquipo/${idDocumento}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    tieneDireccion(id: number): Promise<boolean> {
        let ruta = this.ruta + "/" + id + "/TieneDireccion";
        return new Promise((resolve, reject) => {
            this.clienteHttp.get(ruta).toPromise()
                .then(tieneDireccion => {
                    resolve(Boolean(tieneDireccion));
                })
                .catch(error => [
                    resolve(false)
                ]);
        });
    }


    generarFolio(): Observable<string> {
        const ruta = `${this.ruta}/GenerarFolioVenta`;
        return this.clienteHttp.get(ruta).pipe(
            map((folio: string) => {
                return folio;
            })
        );
    }

    obtenerCotizacionesVenta(clienteId: number, documentoVentaId?: number): Observable<Documento[]> {
        var ruta = '';
        if (documentoVentaId == undefined)
            ruta = `${this.ruta}/ObtenerCotizacionesVenta/${clienteId}`;
        else
            ruta = `${this.ruta}/ObtenerCotizacionesVenta/${clienteId}/${documentoVentaId}`;

        return this.clienteHttp.get(ruta).pipe(
            map((cotizacionesVenta: Documento[]) => {
                return cotizacionesVenta;
            })
        );
    }

    obtenerFoliosCotizacionesVenta(clienteId: number, documentoVentaId?: number): Observable<string[]> {
        var ruta = '';

        if (documentoVentaId == undefined)
            ruta = `${this.ruta}/ObtenerFoliosCotizacionesVenta/${clienteId}`;
        else
            ruta = `${this.ruta}/ObtenerFoliosCotizacionesVenta/${clienteId}/${documentoVentaId}`;

        return this.clienteHttp.get<string[]>(ruta);
    }

    ObtenerVentasPorEnviar(): Observable<Documento[]> {
        const ruta = `${this.ruta}/VentasPorEnviar/`;
        return this.clienteHttp.get(ruta).pipe(
            map((ventas: Documento[]) => {
                return ventas;
            })
        );
    }

    ObtenerUbicacion(id: number): Observable<UbicacionCV[]> {
        const ruta = `${this.ruta}/ObtenerUbicacion/${id}`;
        return this.clienteHttp.get(ruta).pipe(
            map((ubicacion: UbicacionCV[]) => {
                return ubicacion;
            })
        );
    }

    obtenerDescripcionSV(id: number): Observable<DescripcionSV> {
        return this.clienteHttp.get(`${this.ruta}/ObtenerDescripcionSV/${id}`).pipe(
            map((data: DescripcionSV) => {
                return data;
            })
        );
    }

    ObtenerUbicacionDestino(id: number): Observable<UbicacionCV[]> {
        const ruta = `${this.ruta}/ObtenerUbicacionDestino/${id}`;
        return this.clienteHttp.get(ruta).pipe(
            map((ubicacion: UbicacionCV[]) => {
                return ubicacion;
            })
        );
    }

    obtenerSolicitudesEmbarque(cliente: string): Observable<SolicitudEmbarque[]> {
        const ruta = `${this.ruta}/ObtenerSolicitudesEmbarque/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((solicitudesEmbarques: SolicitudEmbarque[]) => {
                return solicitudesEmbarques;
            })
        );
    }

    cancelar(idVenta: number, idMotivo?: number): Observable<void> {
        const ruta = `${this.ruta}/CancelarDocumento/${idVenta}/${idMotivo}`;
        return this.clienteHttp.get<void>(ruta);
    }

    cancelarSCompraVenta(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/CancelarSapCompraVenta`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    cancelarSVenta(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/CancelarSapVenta`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    CancelarSapVentaParaAsociar(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/CancelarSapVentaParaAsociar`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    cancelarSSV(idVenta: number): Observable<void> {
        const ruta = `${this.ruta}/CancelarDocumentoSSV/${idVenta}`;
        return this.clienteHttp.get<void>(ruta);
    }

    cancelarSV(idVenta: number, idMotivo: number): Observable<void> {
        const ruta = `${this.ruta}/CancelarDocumentoSV/${idVenta}/${idMotivo}`;
        return this.clienteHttp.get<void>(ruta);
    }

    buscoFacturaEnSap(idVenta: number): Observable<void> {
        const ruta = `${this.ruta}/BuscoFacturaEnSap/${idVenta}`;
        return this.clienteHttp.get<void>(ruta);
    }

    buscoFacturaOrdenEnSap(idVenta: number): Observable<void> {
        const ruta = `${this.ruta}/BuscoFacturaOrdenEnSap/${idVenta}`;
        return this.clienteHttp.get<void>(ruta);
    }

    buscoListaFacturaOrdenEnSap(documentos: Documento[]): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/BuscoListaFacturaOrdenEnSap`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documentos);
    }

    duplicar(
        documento: DocumentoDuplicar,
        mismoDocumento = false
    ): Observable<Documento> {
        const ruta = `${this.ruta}/Duplicar/${mismoDocumento}`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    duplicarMultiple(
        documento: DocumentoDuplicar,
        numeroCopias: number,
        nuevaCotizacion: boolean
    ): Observable<Documento> {
        const ruta = `${this.ruta}/DuplicarMultiple/${numeroCopias}/${nuevaCotizacion}`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    obtenerAnalisisVentas(): Observable<AnalisisVentas[]> {
        const ruta = `${this.ruta}/ObtenerAnalisisVentas`;
        return this.clienteHttp.get<AnalisisVentas[]>(ruta);
    }

    obtenerAnalisisVentasEspecifico(cv: number): Observable<AnalisisVentas[]> {
        const ruta = `${this.ruta}/ObtenerAnalisisVentasEspecifico/${cv}`;
        return this.clienteHttp.get<AnalisisVentas[]>(ruta);
    }

    buscarAnalisisVentasEspecificoPromise(cv: number): Promise<any> {
        const ruta = `${this.ruta}/ObtenerAnalisisVentasEspecifico/${cv}`;
        return new Promise((resolve, reject) => {
            this.clienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    obtenerSvCerradas(clienteId: number): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerSvCerradas/${clienteId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerFlujo(proyectoId: number): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerFlujo/${proyectoId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerFlujoPorVenta(documentoId: number): Observable<Documento> {
        const ruta = `${this.ruta}/ObtenerFlujoPorVenta/${documentoId}`;
        return this.clienteHttp.get<Documento>(ruta);
    }

    obtenerVentasEmpleado(): Observable<VentasEmpleado[]> {
        const ruta = `${this.ruta}/ObtenerVentasEmpleado`;
        return this.clienteHttp.get<VentasEmpleado[]>(ruta);
    }

    obtenerVentasCanceladas(): Observable<VentasCanceladas[]> {
        const ruta = `${this.ruta}/ObtenerVentasCanceladas`;
        return this.clienteHttp.get<VentasCanceladas[]>(ruta);
    }

    obtenerEstatusVentas(): Observable<EstatusVentas> {
        const ruta = `${this.ruta}/ObtenerEstatusVentas`;
        return this.clienteHttp.get<EstatusVentas>(ruta);
    }

    obtenerPerfectOrder(): Observable<MainGraphicModel> {
        const ruta = `${this.ruta}/ObtenerPerfectOrder`;
        return this.clienteHttp.get<MainGraphicModel>(ruta);
    }


    // obtenerPerfectOrderFiltrado(AnnoFiltro:number): Observable<MainGraphicModel> {        
    //     const ruta = `${this.ruta}/ObtenerPerfectOrderFiltrado?AnnoFiltro=${AnnoFiltro}`;
    //     return this.clienteHttp.get<MainGraphicModel>(ruta);       
    // }

    obtenerCierreDespacho(): Observable<MainGraphicModel> {
        const ruta = `${this.ruta}/ObtenerCierreDespacho`;
        return this.clienteHttp.get<MainGraphicModel>(ruta);
    }

    // obtenerCierreDespachoFiltrado(AnnoFiltro): Observable<MainGraphicModel> {

    //     const ruta = `${this.ruta}/ObtenerCierreDespachoFiltrado?AnnoFiltro=${AnnoFiltro}`;
    //     return this.clienteHttp.get<MainGraphicModel>(ruta);
    // }

    ObtenerOperacionResumen(): Observable<PendienteSAP> {
        const ruta = `${this.ruta}/ObtenerOperacionResumen`;
        return this.clienteHttp.get<PendienteSAP>(ruta);
    }

    ObtenerOperacionResumenVentasSap(): Observable<PendienteSAP>{
        const ruta = `${this.ruta}/ObtenerOperacionResumenVentasSap`;
        return this.clienteHttp.get<PendienteSAP>(ruta);
    }

    ObtenerOperacionResumenFacturacionPendiente(): Observable<PendienteSAP>{
        const ruta = `${this.ruta}/ObtenerOperacionResumenFacturacionPendiente`;
        return this.clienteHttp.get<PendienteSAP>(ruta);
    }

    obtenerRentabilidad(): Observable<MainGraphicModel> {
        const ruta = `${this.ruta}/ObtenerRentabilidad`;
        return this.clienteHttp.get<MainGraphicModel>(ruta);
    }


    // obtenerRentabilidadFiltrado(AnnoFiltro:number): Observable<MainGraphicModel> {
    //     const ruta = `${this.ruta}/ObtenerRentabilidadFiltrado?AnnoFiltro=${AnnoFiltro}`;
    //     return this.clienteHttp.get<MainGraphicModel>(ruta);
    // }

    obtenerFacturacionPendiente(): Observable<MainGraphicModel2> {
        const ruta = `${this.ruta}/obtenerFacturacionPendiente`;
        return this.clienteHttp.get<MainGraphicModel2>(ruta);
    }

    // obtenerFacturacionPendienteFiltrado(AnnoFiltro:number): Observable<MainGraphicModel2> {

    //     const ruta = `${this.ruta}/obtenerFacturacionPendienteFiltrado?AnnoFiltro=${AnnoFiltro}`;

    //     return this.clienteHttp.get<MainGraphicModel2>(ruta);
    // }

    obtenerPartidas(): Observable<MainGraphicPartidas> {
        const ruta = `${this.ruta}/obtenerPartidas`;
        return this.clienteHttp.get<MainGraphicPartidas>(ruta);
    }

    // obtenerPartidasFiltrado(AnnoFiltro:number): Observable<MainGraphicPartidas> {        
    //     const ruta = `${this.ruta}/obtenerPartidasFiltrado?AnnoFiltro=${AnnoFiltro}`;
    //     return this.clienteHttp.get<MainGraphicPartidas>(ruta);
    // }

   

    solicitarCostos(
        documentoId: number,
        proveedores: Socio[]
    ): Observable<Documento[]> {
        const ruta = `${this.ruta}/SolicitarCostos/${documentoId}`;
        return this.clienteHttp.post<Documento[]>(ruta, proveedores);
    }

    cerrarVenta(idVenta: number): Observable<boolean> {
        const ruta = `${this.ruta}/Cerrar/${idVenta}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    cerrarVentaNoFacturar(idVenta: number): Observable<boolean> {
        const ruta = `${this.ruta}/CerrarNoFacturar/${idVenta}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    //PROCESO PARA LA OBTENCION DE LAS CV GLOBALES POR PROYECTO
    obtenerTodosProyectoId(proyectoId, monedaId): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerTodosProyectoId/${proyectoId}/${monedaId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerCVSProyectoId(proyectoId, monedaId): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerCVSProyectoId/${proyectoId}/${monedaId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerPorFolio(folio: string): Observable<Documento> {
        const ruta = `${this.ruta}/ObtenerVentaCompletaPorFolio/${folio}`;
        return this.clienteHttp.get<Documento>(ruta);
    }

    ObtenerCVsPorProyectoId(proyectoId): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerCVsPorProyectoId/${proyectoId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    asignarProyectoCV(
        asignacion: AsignacionProyectosCV
    ): Observable<AsignacionProyectosCV> {
        const ruta = `${this.ruta}/AsignarProyectoCV`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionProyectosCV) => asig)
        );
    }

    desasignarProyectoCV(
        asignacion: AsignacionProyectosCV
    ): Observable<AsignacionProyectosCV> {
        const ruta = `${this.ruta}/DesasignarProyectoCV`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionProyectosCV) => asig)
        );
    }

    /// PROCESO PARA LA OBTENCION DE LAS CV GLOBALES POR SV

    obtenerTodosSVId(svId, monedaId): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerTodosSVId/${svId}/${monedaId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerCVSSVId(svId, monedaId): Observable<Documento[]> {
        const ruta = `${this.ruta}/ObtenerCVSSVId/${svId}/${monedaId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    asignarSVCV(asignacion: AsignacionSVCV): Observable<AsignacionSVCV> {
        const ruta = `${this.ruta}/AsignarSVCV`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionSVCV) => asig)
        );
    }

    desasignarSVCV(asignacion: AsignacionSVCV): Observable<AsignacionSVCV> {
        const ruta = `${this.ruta}/DesasignarSVCV`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionSVCV) => asig)
        );
    }

    obtenerEvidencias(svId: number): Observable<Archivo[]> {
        const ruta = `${this.ruta}/Evidencias/${svId}`;
        return this.clienteHttp.get<Archivo[]>(ruta);
    }

    guardarEvidencias(svId: number, evidencias: Archivo[]) {
        const ruta = `${this.ruta}/Evidencias/${svId}`;
        return this.clienteHttp.post(ruta, evidencias);
    }

    descargarEvidencia(idEvidencia: number): Observable<Blob> {
        const ruta = `${this.ruta}/Evidencias/Descargar/${idEvidencia}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        });
    }

    ObtenerSV(cvId: number): Observable<Documento> {
        const ruta = `${this.ruta}/ObtenerSV/${cvId}`;
        return this.clienteHttp.get<Documento>(ruta);
    }

    actualizarMotivoNoEnvio(motivo: Motivo): Observable<object> {
        const ruta = `${this.ruta}/ActualizarMotivoNoEnvio`;
        return this.clienteHttp.put<object>(ruta, motivo);
    }

    actualizarMotivoNoFacturar(motivo: Motivo): Observable<object> {
        const ruta = `${this.ruta}/ActualizarMotivoNoFacturar`;
        return this.clienteHttp.put<object>(ruta, motivo);
    }

    tieneNumeroFactura(id: number): Observable<boolean> {
        const ruta = `${this.ruta}/TieneNumeroFactura/${id}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    asignarIdCVEnvioSAP(documentoId: number, documentos: Documento[]): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/AsignarIdCVEnvioSAP/${documentoId}`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documentos);
    }


    obtenerOrdenVentaFacturadaSAP(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/ObtenerOrdenVentaFacturadaSAP`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    abrirDocumentoCV(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/AbrirDocumentoCV`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    asignarDocumentosNoFacturados(documentoId: number, tipos: TiposDocumentos[]): Observable<Respuesta<TiposDocumentos>> {
        const ruta = `${this.ruta}/AsignarDocumentosNoFacturados/${documentoId}`;
        return this.clienteHttp.post<Respuesta<TiposDocumentos>>(ruta, tipos);
    }

    abrirDocumento(documentoId: number): Observable<void> {
        const ruta = `${this.ruta}/AbrirDocumento/${documentoId}`;
        return this.clienteHttp.get<void>(ruta);
    }

    tieneSeguimiento(documentoId: number): Observable<boolean> {
        const ruta = `${this.ruta}/TieneSeguimientoOnMonitor/${documentoId}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    preactualizarCV(cvId: number): Promise<any> {
        const ruta = `${this.ruta}/PreActualizacionCV/${cvId}`;
        return new Promise((resolve, reject) => {
            this.clienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    reporteCostoKm(): Promise<any> {
        const ruta = `${this.ruta}/ReporteCostoKm`;
        return new Promise((resolve, reject) => {
            this.clienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }


}
