import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaMotivoDesfaseComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { MotivoCierre } from 'src/app/entidades/motivo-cierre';

@Component({
  selector: 'catalogo-motivo-desfase',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoMotivoDesfaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<MotivoCierre> = new MatTableDataSource(
    []
  );
  columnasMostradas = ['clave', 'descripcion', 'activo','opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() {
    this.cargarArticulos();
  }

  cargarArticulos(): void {
    this.cargando = true;
    this.ctx.motivosDesfase.obtenerTodos().subscribe(motivosDesfase => {
      this.fuenteDatos = new MatTableDataSource(motivosDesfase);
      this.fuenteDatos.paginator = this.paginador;motivosDesfase
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  abrirForma(id?: number): void {
    const dato = id ? id : null;
    const forma = this.ventana.open(FormaMotivoDesfaseComponent, {
      data: dato,
      panelClass: 'form-container'
    });

    this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
      this.cargarArticulos();
    });
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    }
    else {
      this.desactivar(id);
    }
  }



  activar(id: number): void {
    this.subsActivar = this.ctx
      .motivosDesfase.activar(id)
      .subscribe(() => {
        this.alerta.mostrarExito('Motivo activado');
      },
        error => {
          this.alerta.mostrarError('Error al activar');
        }
      );
  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx
      .motivosDesfase.desactivar(id)
      .subscribe(() => {
        this.alerta.mostrarExito('Motivo desactivado');
      },
        error => {
          this.alerta.mostrarError('Error al desactivar');
        }
      );
  }


  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsArticulos) {
      this.subsArticulos.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}
