import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaMotivoCierreComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { MotivoCierre } from 'src/app/entidades/motivo-cierre';

@Component({
  selector: 'catalogo-motivo-cierre',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoMotivoCierreComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<MotivoCierre> = new MatTableDataSource(
    []
  );
  columnasMostradas = ['clave', 'descripcion', 'activo', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() {
    this.cargarArticulos();
  }

  cargarArticulos(): void {
    this.cargando = true;
    this.ctx.motivosCierre.obtenerTodos().subscribe(motivosCierre => {
      this.fuenteDatos = new MatTableDataSource(motivosCierre);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  abrirForma(id?: number): void {
    const dato = id ? id : null;
    const forma = this.ventana.open(FormaMotivoCierreComponent, {
      data: dato,
      panelClass: 'form-container'
    });

    this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
      this.cargarArticulos();
    });
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    }
    else {
      this.desactivar(id);
    }
  }


  activar(id: number): void {

    this.ctx.motivosCierre.obtenerTodos().subscribe(motivosCierre => {
      debugger
      var motivosActivos = motivosCierre.filter(e => e.activo == true);

      if (motivosActivos.length > 0) {
        this.alerta.mostrarError('Solo un motivo de cierre automatico puede estar activo');
      } else {
        this.subsActivar = this.ctx
          .motivosCierre.activar(id)
          .subscribe(() => {
            this.alerta.mostrarExito('Motivo de cierre activado');
          },
            error => {
              this.alerta.mostrarError('Error al activar');
            }
          );
      }
    });


  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx
      .motivosCierre.desactivar(id)
      .subscribe(() => {
        this.alerta.mostrarExito('Motivo de cierre desactivado');
      },
        error => {
          this.alerta.mostrarError('Error al desactivar');
        }
      );
  }


  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx.motivosCierre
      .eliminar(id.toString())
      .subscribe(
        () => {
          this.alerta.mostrarError('Registro eliminado');
          this.cargarArticulos();
        },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsArticulos) {
      this.subsArticulos.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}
