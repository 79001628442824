import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription, Observable } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoProyecto } from 'src/app/entidades/tipo-proyecto';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Router } from '@angular/router';
import { FlujoService } from 'src/app/modulos/flujo-ventas/servicios/flujo.service';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ComponentesService } from 'src/app/modulos/operaciones/servicios/componentes.service';
import { UtilidadProyectoComponent } from '../utilidad-proyectos/utilidad-proyecto.component';
import { ProyectoCerradoDetalleComponent } from '../catalogo/proyecto-cerrado-detalle/proyecto-cerrado-detalle.component';

@Component({
    selector: 'catalogo-proyecto-cerrados',
    templateUrl: './catalogo-proyectos-cerrados.component.html',
    styleUrls: ['./catalogo-proyectos-cerrados.scss'],
    animations: [animacionTabla],
})
@AutoUnsubscribe()
export class CatalogoProyectosCerradosComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatos: MatTableDataSource<Proyecto> = new MatTableDataSource([]);
    columnasMostradas = [
        'folio',
        'descripcion',
        'fechaDocumento',
        'nombreUsuario',
        'esInternacional'
    ];
    cargando = true;

    subsCompra: Subscription;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 50;
        }
        return 100;
    }

    subsProyecto: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    subsSolicitud: Subscription;
    tiposProyecto: Observable<TipoProyecto[]>;

    rowExapandido: Proyecto | null = null;

    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        private parametrosService: ComponentesService,
        private servicioFlujo: FlujoService,
        private router: Router,
        private spinner: LoadingService,
        private ventanaModal: MatDialog
    ) { }

    idCliente: number;
    subsCompras: Subscription;
    usuarioId: number;

    ngOnInit() {
        this.idCliente = this.autenticacion.perfil.id;
        this.cargarProyectos();
    }

    cargarProyectos(): void {
        this.cargando = true;
        this.ctx.proyectos
            .obtenerProyectosCerrados(this.idCliente)
            .subscribe((Proyectos) => {
                this.fuenteDatos = new MatTableDataSource(Proyectos);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.cargando = false;
            });
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    mostrarFlujo(proyecto: Proyecto): void {
        this.spinner.show('Cargando el flujo de documentos...');
        this.servicioFlujo
            .cargarParametros(proyecto, true, true)
            .toPromise()
            .then(() => {
                this.router
                    .navigate(['Empresa/FlujoVentas'])
                    .then(() => this.spinner.hide());
            });
    }

    mostrarProyecto(id?: number): void {
        const dato = id ? id : null;
        this.ventanaModal.open(UtilidadProyectoComponent, { data: dato });
    }

    recargarProyectos(): void {
        this.cargarProyectos();
    }

    activar(proyectoOrigenId: number): void {
        this.spinner.show('Activando proyecto');
        this.ctx.proyectos
            .abrirProyectoCerrado(proyectoOrigenId)
            .toPromise()
            .then(() => {
                this.alerta.mostrarExito('Proyecto activado');
                this.spinner.hide();
                this.router.navigateByUrl('Empresa/Proyectos');
            })
            .catch((err) => {
                console.error(err);
                this.alerta.mostrarError('Error al activar');
            });
    }

    abrirProyecto(id?: number) {
        this.spinner.show('Activando proyecto');
        this.ctx.proyectos
            .abrirProyecto(id)
            .toPromise()
            .then(() => {
                this.alerta.mostrarExito('Proyecto activado');
                this.spinner.hide();
                this.router.navigateByUrl('Empresa/Proyectos');
            })
            .catch((err) => {
                console.error(err);
                this.alerta.mostrarError('Error al activar');
            });
    }

    abrirProyectoDetalle(proyectoId: number): void {
        this.ventana
        .open(ProyectoCerradoDetalleComponent, {
            data: { proyectoId },
            panelClass: 'form-container',
            width: '70vw'
        })
        .afterClosed().subscribe(() => {
            this.cargarProyectos();
        })
    }
    
    ngOnDestroy(): void { }
}
